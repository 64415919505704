@import "./Helpers.scss";
@import url("//fonts.googleapis.com/css?family=Lato:300,400,700,400italic");

* {
  box-sizing: border-box;
}

html {
  opacity: 1;
  visibility: visible;
}

body {
  background-color: $color--black-lighten-80;
  color: $color--black;
  font-family: $font-family--default;
  font-size: font-scale();
  line-height: line-height-scale();
}

html,
body,
#root,
#root > div {
  height: 100%;
  margin: 0;
}
