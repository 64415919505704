@import "Helpers.scss";

.layout {
  display: flex;
  flex-flow: column;
}

.replicator {
  display: flex;
  flex: 1 1 auto;
}

.loading_container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFFAA;
}

.sessions_container {
  width:15%;
  border-right-color: #333366;
  border-right-width: 2px;
  border-right-style: solid;
  display: flex;
  flex-direction: column;
}

.action_container {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_container {
  padding: 5px;
  flex:1;
}

.create_container {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data_container {
  padding: 5px 10px;
  display: flex;
  min-width: 85%;
  flex-direction: column;
}

.name_container {
  padding: 5px;
}

.run_container {
  padding: 5px;
}

.input_container {
  padding: 5px;
}

.settings_container {
  padding: 5px;
  display: flex;
  flex-flow: column;

  & > div {
    display: flex;
    align-items: center;
    line-height: 1;
  }
}

.script_container {
  padding: 5px;
  min-height: 40%;
  height: 300px;
  flex:2;
}

.log_container {
  padding: 5px;
  flex: 1;
}