@import "@paprika/tokens/lib/tokens.scss";
@import "@paprika/stylers/lib/helpers.scss";
@import url("//fonts.googleapis.com/css?family=Lato:300,400,700,400italic");

$node-diameter: 72px;
$node-border: 3px;
$joint-diameter: 24px;
$joint-offset-x: 2px;

// need to figure out how to access these in javascript - might need to adjust webpack
:export {
  nodeDiameter: $node-diameter;
  jointDiameter: $joint-diameter;
  jointOffsetX: $joint-offset-x;
}
